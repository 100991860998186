import * as assmtFlow from "../../pages/AssmntStart/flow";
import * as testFlow from "../../pages/TestModule/flow";
import { assessmentService, testService } from "../../services";
import { flowControlSlice } from "./slice";
const slice = flowControlSlice.actions;

export const fetchInvite = (inviteId) => {
  return async (dispatch) => {
    try {
      dispatch(slice.fetchInvite());
      const response = await assessmentService.getCandidateInvite(inviteId);
      const _progress = assmtFlow.getTestProgressDTO({});
      const payload = {
        inviteInfo: {
          _id: response._id,
          status: response.status,
          hasExpired: response.hasExpired,
          invitationUrl: response.invitationUrl,
        },
        candidateInfo: {
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
        },
        assessmentInfo: {
          _id: response.assessment._id,
          title: response.assessment.title,
          time: response.assessment.time,
        },
        progress: _progress,
        steps: Object.entries(_progress).reduce((a, c) => {
          const [k, v] = c;
          a[k] = !v.skip;
          return a;
        }, {}),
        tests: {
          testList: response.assessment.tests,
          totalTests: response.assessment.tests.length,
          currTestStatus: "idle",
          currTestIndex: 0,
        },
      };
      dispatch(slice.initializeAssessment(payload));
    } catch (error) {
      dispatch(slice.inviteError());
    }
  };
};

export const setInvite = (obj) => {
  return (dispatch) => {
    const _progress = assmtFlow.getTestProgressDTO({ isPublicLink: true });
    const payload = {
      inviteInfo: {
        _id: obj._id,
        status: obj.status,
        hasExpired: obj.hasExpired,
        invitationUrl: null,
      },
      candidateInfo: {
        firstName: obj.firstName,
        lastName: obj.lastName,
        email: obj.email,
      },
      assessmentInfo: {
        _id: obj.assessment._id,
        title: obj.assessment.title,
        time: obj.assessment.time,
      },
      progress: _progress,
      steps: Object.entries(_progress).reduce((a, c) => {
        const [k, v] = c;
        a[k] = !v.skip;
        return a;
      }, {}),
      tests: {
        testList: obj.assessment.tests,
        totalTests: obj.assessment.tests.length,
        currTestStatus: "idle",
        currTestIndex: 0,
      },
    };
    dispatch(slice.initializeAssessment(payload));
  };
};
export const fetchAssessment = (assessmentId) => {
  return async (dispatch) => {
    try {
      dispatch(slice.fetchInvite());
      const response = await assessmentService.getAssessment(assessmentId);
      const _progress = assmtFlow.getTestProgressDTO({});
      const payload = {
        inviteInfo: {
          _id: response._id,
          status: response.status,
          hasExpired: response.hasExpired,
          invitationUrl: response.invitationUrl,
        },
        candidateInfo: {
          firstName: response.name,
          lastName: response.lastName,
          email: response.email,
        },
        assessmentInfo: {
          _id: response.assessment._id,
          title: response.assessment.title,
          time: response.assessment.time,
        },
        progress: _progress,
        steps: Object.entries(_progress).reduce((a, c) => {
          const [k, v] = c;
          a[k] = !v.skip;
          return a;
        }, {}),
        tests: {
          testList: response.assessment.tests,
          totalTests: response.assessment.tests.length,
          currTestStatus: "idle",
          currTestIndex: 0,
        },
      };
      dispatch(slice.initializeAssessment(payload));
    } catch (error) {
      dispatch(slice.inviteError());
    }
  };
};
export const fetchTest = (testId) => {
  return async (dispatch) => {
    try {
      dispatch(slice.fetchTest());
      const response = await testService.fetchTestDetail(testId);
      const data = response.data?.data;
      if (!Array.isArray(data)) throw new Error("Custom");
      const [test] = data;

      const _progress = testFlow.getTestProgressDTO({ hasPracticeQ: false });
      const _steps = Object.entries(_progress).reduce((a, c) => {
        const [k, v] = c;
        a[k] = !v.skip;
        return a;
      }, {});
      const testInfo = {
        _id: test._id,
        title: test.questionTitle,
        logo: test.logo,
        detail: test.detail,
        skill: test.testType?.label,
        subSkills: test.questionTypes,
        time: test.time,
        guidelines: test.guidelines.map((item) => item.content),
        aboutUs: test.aboutUs,
      };
      const totalAQ = test.questions.length;
      const actualQ = test.questions;
      const payload = {
        testInfo: testInfo,
        progress: _progress,
        steps: _steps,
        totalAQ: totalAQ,
        actualQ: actualQ,
        isFetching: false,
        error: null,
      };
      dispatch(slice.initializeTest(payload));
    } catch (error) {
      console.error(error);
      dispatch(slice.TestError());
    }
  };
};
export const sendOtpAction = (data) => {
  return async (dispatch) => {
    try {
      console.log("sendOtpAction", data);
      await testService.sendOtp(data);
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      // dispatch(slice.submitAnswerFailed());
      return Promise.reject(error);
    }
  };
};
export const verifyOtpAction = (stepKey, data) => {
  return async (dispatch) => {
    try {
      console.log("verifyOtpAction", stepKey, data);

      await testService.verifyOtp(data);
      dispatch(slice.markAssessmentStepComplete(stepKey));
      return Promise.resolve();
    } catch (error) {
      // dispatch(slice.submitAnswerFailed());
      console.log(error);
      return Promise.reject(error);
    }
  };
};
export const saveAnswer = (data, isLast) => {
  return async (dispatch) => {
    const key = `${data.invitationId}_${data.testId}_${data.questionId}`;
    try {
      const status = sessionStorage.getItem(key);
      if (status === "succeed" || status === "processing")
        return Promise.reject("Already working on it...");
      sessionStorage.setItem(key, "processing");
      await testService.submitAnswer(data).catch((err) => {
        if (err?.response?.data?.msg === "Question Already Answered") {
          console.info("-----------Question Already Answered------------");
          return;
        }
        throw new Error(err?.response?.data?.msg);
      });
      if (isLast === false) {
        dispatch(slice.markQuestionComplete());
      }
      sessionStorage.setItem(key, "succeed");
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      sessionStorage.setItem(key, "failed");
      return Promise.reject(error);
    }
  };
};

export const submitTerminateAssessment = (params) => {
  return async (dispatch) => {
    try {
      await assessmentService.getAssessmentResult(params);
      dispatch(slice.terminateAssessment());
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };
};

export const initializeAV2 = (data) => {
  return (dispatch) => {
    const tests = data.tests;
    const isProctoringEnabled =
      data.assessment.isAntiCheatingSettingEnabled === true;
    const hasMultipleLang = data.assessment.languages.length > 1;
    const assessmentProgress = assmtFlow.getTestProgressDTO({
      isPublicLink: true,
      isProctoringEnabled,
      hasMultipleLang,
    });
    const assessmentSteps = Object.entries(assessmentProgress).reduce(
      (a, c) => {
        const [k, v] = c;
        a[k] = !v.skip;
        return a;
      },
      {}
    );

    const payload = {
      __initialized: true,
      steps: assessmentSteps,
      progress: assessmentProgress,
      tests: {
        totalTests: tests.length,
        currTestIndex: 0,
      },
    };
    dispatch(slice.initializeAV2(payload));
  };
};

export const updateAV2 = (data) => {
  return (dispatch) => {
    dispatch(slice.initializeAV2(data));
  };
};

export const initializeTV2 = (data, i) => {
  return (dispatch) => {
    const tests = data.tests;
    const currentTest = tests[i];
    const isFirstTest = i === 0;
    const testEndingInstructions = data?.assessment?.testEndingPage;
    const endingInstruction = testEndingInstructions.find(
      (instruction) => instruction.testId === currentTest._id
    );
    const showEndPage = endingInstruction?.progressPage;
    const testIdOfCurrentTest = endingInstruction?.testId;
    const currentTestTitle = endingInstruction?.progressTestTitle;
    console.log({
      testEndingInstructions,
      endingInstruction,
      showEndPage,
      testIdOfCurrentTest,
      currentTestTitle,
    });
    const testProgress = testFlow.getTestProgressDTO({
      hasPracticeQ: false,
      isFirstTest,
      showEndPage,
    });
    const testSteps = Object.entries(testProgress).reduce((a, c) => {
      const [k, v] = c;
      a[k] = !v.skip;
      return a;
    }, {});

    const payload = {
      __initialized: true,
      index: i,
      steps: testSteps,
      progress: testProgress,
      totalAQ: currentTest.questions.length,
      currAQIndex: 0,
      isSaving: false,
    };
    localStorage.setItem("TPayload-" + i, JSON.stringify(payload));
    localStorage.setItem(
      "TArgs" + i,
      JSON.stringify({ testProgress, showEndPage, isFirstTest })
    );
    dispatch(slice.initializeTV2(payload));
  };
};

export const updateTV2 = (data) => {
  return (dispatch) => {
    dispatch(slice.initializeTV2(data));
  };
};

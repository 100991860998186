export const STEPS = {
  Test_GuideLine: {
    order: 1,
    slug: "Test_GuideLine",
  },
  Practice_Question: {
    order: 2,
    slug: "Practice_Question",
  },
  Test_Start_Alert: {
    order: 3,
    slug: "Test_Start_Alert",
  },
  Actual_Queston: {
    order: 4,
    slug: "Actual_Queston",
  },
  Test_Complete: {
    order: 5,
    slug: "Test_Complete",
  },
};

export const QTYPES = {
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
};

export const TestProgressDTO = {
  [STEPS.Test_GuideLine.slug]: {
    completed: false,
    skip: false,
  },
  [STEPS.Practice_Question.slug]: {
    completed: false,
    skip: false,
  },
  [STEPS.Test_Start_Alert.slug]: {
    completed: false,
    skip: false,
  },
  [STEPS.Actual_Queston.slug]: {
    completed: false,
    skip: false,
  },
  [STEPS.Test_Complete.slug]: {
    completed: false,
    skip: false,
  },
};

export const getTestProgressDTO = ({
  hasPracticeQ = false,
  isFirstTest,
  showEndPage,
}) => {
  return {
    [STEPS.Test_GuideLine.slug]: {
      completed: false,
      skipped: false,
    },
    [STEPS.Practice_Question.slug]: {
      completed: false,
      skipped: false,
    },
    [STEPS.Test_Start_Alert.slug]: {
      completed: false,
      skipped: !isFirstTest,
    },
    [STEPS.Actual_Queston.slug]: {
      completed: false,
      skipped: false,
    },
    [STEPS.Test_Complete.slug]: {
      completed: false,
      skipped: !showEndPage,
    },
  };
};
